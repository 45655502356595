<template>
    <div>
        <footer class="text-muted">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="float-right">
                            <a
                                href="javascript:"
                                title="Voltar ao topo"
                                class="btn btn-circle btn-outline-primary btn-table mn-bt-top"
                                @click="backToTop"
                            >
                                <i class="fa fa-arrow-up"></i>
                            </a>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-xl-4 col-md-4 col-sm-6 text-left">
                        <h5 class="h5">NOSSA MISSÃO</h5>
                        <p>
                            Somos uma empresa 100% nacional do setor de educação digital.
                            Nossa missão é oferecer aos nossos alunos as soluções mais inovadoras,
                            funcionais e tecnológicas para o ensino infantil e fundamental.
                        </p>
                    </div>
                    <div class="col-12 col-xl-3 offset-md-1 col-sm-4 text-left">
                        <h5 class="h5">Links Úteis</h5>
                        <p><a href="/complaints">Canal de Ética</a></p>
                        <p>
                            <a href="javascript:" @click="openForm">
                                Trabalhe Conosco
                            </a>
                        </p>
                        <p>
                            <a href="/terms">Termos de Uso</a>
                        </p>
                        <p>
                            <a href="/policy">Política de Privacidade</a>
                        </p>
                    </div>
                    <div class="col-12 col-xl-2 offset-xl-2 offset-md-1 col-md-2 col-sm-3 text-sm-right text-center">
                        <a href="https://connect.gptw.info/certified-company?s=73576d45-5f5e-4793-abbc-91a835a5ed01"
                           target="_blank">
                            <img
                                width="210"
                                alt="logo, great place to work"
                                src="/assets/img/selo-gptw.png"
                                title="Somos um ótimo lugar para trabalhar!"
                            >
                        </a>
                    </div>
                </div>
                <hr />
                <div class="row d-flex">
                    <div class="col-sm-12 col-md-6 mb-3">
                        <span class="float-left text-center">
                            © 2012/{{ currentYear }} Mentes Notáveis. Todos os direitos reservados.
                        </span>
                    </div>
                    <div class="col-sm-12 col-md-6 mb-3">
                        <span class="float-right text-center">
                            Mentes Notáveis Sis. e Cons. de Inf. LTDA - 32.131.669/0001-09
                        </span>
                    </div>
                </div>
            </div>
        </footer>

        <app-work-us ref="workUs"></app-work-us>
    </div>
</template>

<script>
    import WorkUsComponent from '../components/WorkUsComponent';

    export default {
        components: {
            'app-work-us': WorkUsComponent,
        },
        data() {
            return {
                currentYear: new Date().getFullYear()
            }
        },
        methods: {
            backToTop() {
                $('html, body').animate({
                    scrollTop: 0
                }, 700);
            },
            openForm() {
                this.$refs.workUs.open();
            }
        }
    }
</script>
