var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('footer',{staticClass:"text-muted"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"float-right"},[_c('a',{staticClass:"btn btn-circle btn-outline-primary btn-table mn-bt-top",attrs:{"href":"javascript:","title":"Voltar ao topo"},on:{"click":_vm.backToTop}},[_c('i',{staticClass:"fa fa-arrow-up"})])])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-3 offset-md-1 col-sm-4 text-left"},[_c('h5',{staticClass:"h5"},[_vm._v("Links Úteis")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('p',[_c('a',{attrs:{"href":"javascript:"},on:{"click":_vm.openForm}},[_vm._v("\n                            Trabalhe Conosco\n                        ")])]),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3)]),_vm._v(" "),_vm._m(4)]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"row d-flex"},[_c('div',{staticClass:"col-sm-12 col-md-6 mb-3"},[_c('span',{staticClass:"float-left text-center"},[_vm._v("\n                        © 2012/"+_vm._s(_vm.currentYear)+" Mentes Notáveis. Todos os direitos reservados.\n                    ")])]),_vm._v(" "),_vm._m(5)])])]),_vm._v(" "),_c('app-work-us',{ref:"workUs"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-xl-4 col-md-4 col-sm-6 text-left"},[_c('h5',{staticClass:"h5"},[_vm._v("NOSSA MISSÃO")]),_vm._v(" "),_c('p',[_vm._v("\n                        Somos uma empresa 100% nacional do setor de educação digital.\n                        Nossa missão é oferecer aos nossos alunos as soluções mais inovadoras,\n                        funcionais e tecnológicas para o ensino infantil e fundamental.\n                    ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/complaints"}},[_vm._v("Canal de Ética")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/terms"}},[_vm._v("Termos de Uso")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/policy"}},[_vm._v("Política de Privacidade")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-xl-2 offset-xl-2 offset-md-1 col-md-2 col-sm-3 text-sm-right text-center"},[_c('a',{attrs:{"href":"https://connect.gptw.info/certified-company?s=73576d45-5f5e-4793-abbc-91a835a5ed01","target":"_blank"}},[_c('img',{attrs:{"width":"210","alt":"logo, great place to work","src":"/assets/img/selo-gptw.png","title":"Somos um ótimo lugar para trabalhar!"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-6 mb-3"},[_c('span',{staticClass:"float-right text-center"},[_vm._v("\n                        Mentes Notáveis Sis. e Cons. de Inf. LTDA - 32.131.669/0001-09\n                    ")])])
}]

export { render, staticRenderFns }